import React, { useState, useEffect } from "react";
import "./Login.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Loader from "./loader/Loader";
import Customsnackbar from "./snackbar/Customsnackbar";
import { jwtDecode } from "jwt-decode";

function Login({ onbalnaceapi }) {
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordTwo, setpasswordTwo] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    color: "", // Default color
  });
  const [isRegister, setIsRegister] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const isRegisterPage = location.pathname.includes("/register");
    const isForgetPasswordPage = location.pathname.includes("/forget-password");
    setIsForgetPassword(isForgetPasswordPage);
    setIsRegister(isRegisterPage);
  }, [location.pathname]);

  async function loginCheck() {
    if (isForgetPassword) {
      // Forget password logic
      if (email.length === 0) {
        setSnackbarData({
          message: "Email cannot be null",
          color: "error",
        });
        setOpen(true);
        return;
      }
      try {
        setLoading(true);
        const response = await fetch(
          "https://otpseller.store/apitest/forget.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
          }
        );
        const dataReceived = await response.json();
        setLoading(false);
        if (dataReceived.status === 200) {
          setSnackbarData({
            message: dataReceived.msg,
            color: "success",
          });
          setOpen(true);
        } else {
          throw new Error("Invalid credentials");
        }
      } catch (error) {
        setSnackbarData({
          message: "Invalid Email",
          color: "error",
        });
        setOpen(true);
        setEmail("");
      }
    } else if (isRegister) {
      // Registration logic
      if (
        email.length === 0 ||
        password.length === 0 ||
        passwordTwo.length === 0
      ) {
        setSnackbarData({
          message: "Email and password cannot be null",
          color: "error",
        });
        setOpen(true);
        return;
      } else if (password !== passwordTwo) {
        setSnackbarData({
          message: "Password and Confirm Password cannot match",
          color: "error",
        });
        setOpen(true);
        return;
      }
      if (password.length < 6) {
        setSnackbarData({
          message: "Password is weak",
          color: "error",
        });
        setOpen(true);
        return;
      }
      try {
        setLoading(true);
        const response = await fetch(
          "https://otpseller.store/apitest/register.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password, name }),
          }
        );
        const dataReceived = await response.json();
        setLoading(false);
        if (dataReceived.status === 200) {
          const token = dataReceived.data.jwt;
          localStorage.setItem("token", token);
          const decodedToken = jwtDecode(token);
          onbalnaceapi(decodedToken.balance, decodedToken.name);
          navigate("/");
        } else {
          setSnackbarData({
            message: dataReceived.msg,
            color: "error",
          });
          setOpen(true);
        }
      } catch (error) {
        setSnackbarData({
          message: "Something went wrong",
          color: "error",
        });
        setOpen(true);
        setEmail("");
        setName("");
        setPassword("");
        setpasswordTwo("");
      }
    } else {
      // Login logic
      if (email.length === 0 || password.length === 0) {
        setSnackbarData({
          message: "Name , Email and password cannot be null",
          color: "error",
        });
        setOpen(true);
        return;
      }

      try {
        setLoading(true);
        const response = await fetch(
          "https://otpseller.store/apitest/login.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
          }
        );
        const dataReceived = await response.json();
        setLoading(false);
        if (response.ok) {
          const token = dataReceived.data.jwt;
          localStorage.setItem("token", token);
          const decodedToken = jwtDecode(token);
          if (dataReceived.data.jwtadmin !== "") {
            const tokenAdmin = dataReceived.data.jwtadmin;
            localStorage.setItem("tokenAdmin", tokenAdmin);
          }
          onbalnaceapi(decodedToken.balance, decodedToken.name);
          navigate("/");
        } else {
          throw new Error("Invalid credentials");
        }
      } catch (error) {
        setSnackbarData({
          message: "Invalid Username or Password",
          color: "error",
        });
        setOpen(true);
        setEmail("");
        setPassword("");
      }
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <section className="mb-3 mt-3 gradient-custom main">
      <Customsnackbar
        open={open}
        onClose={handleClose}
        message={snackbarData.message}
        color={snackbarData.color}
      />
      {loading && <Loader />}
      <div
        className="row justify-content-center align-items-center"
        style={{ marginRight: "0" }}
      >
        <div className="col-11 col-md-6 col-lg-6 col-xl-5">
          <div
            className="card bg-dark text-white"
            style={{ borderRadius: "1rem" }}
          >
            <div className="card-body p-5 text-center">
              <div className="mb-md-4">
                <h2 className="fw-bold mb-2 text-uppercase">
                  {isForgetPassword
                    ? "Forgot Password"
                    : isRegister
                    ? "Register"
                    : "Login"}
                </h2>
                <p className="text-white-50 mb-4">
                  {!isForgetPassword && !isRegister
                    ? "Please enter your email and password!"
                    : isRegister
                    ? "Please enter your registration details"
                    : "Please enter your email!"}
                </p>
                {isRegister && (
                  <div className="form-outline form-white mb-4">
                    <label className="form-label" htmlFor="typename">
                      Name
                    </label>
                    <input
                      type="email"
                      id="typename"
                      className="form-control form-control-lg"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                )}
                <div className="form-outline form-white mb-4">
                  <label className="form-label" htmlFor="typeEmailX">
                    Email
                  </label>
                  <input
                    type="email"
                    id="typeEmailX"
                    className="form-control form-control-lg"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>

                {!isForgetPassword && (
                  <div className="form-outline form-white mb-4">
                    <label className="form-label" htmlFor="typePasswordX">
                      Password
                    </label>
                    <input
                      type="password"
                      id="typePasswordX"
                      className="form-control form-control-lg"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </div>
                )}

                {!isForgetPassword && isRegister && (
                  <div className="form-outline form-white mb-4">
                    <label className="form-label" htmlFor="typePasswordXY">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      id="typePasswordXY"
                      className="form-control form-control-lg"
                      onChange={(e) => setpasswordTwo(e.target.value)}
                      value={passwordTwo}
                    />
                  </div>
                )}

                {isForgetPassword && (
                  <p className="small mb-5 pb-lg-2">
                    <Link className="text-white-50 noUnderline" to={"/login"}>
                      Back to Login
                    </Link>
                  </p>
                )}

                {isRegister ? (
                  <p className="small mb-5 pb-lg-2">
                    <Link className="text-white-50 noUnderline" to={"/login"}>
                      Already have an account? Login
                    </Link>
                  </p>
                ) : !isForgetPassword ? (
                  <p className="small pb-lg-2">
                    <Link
                      className="text-white-50 noUnderline"
                      to={"/register"}
                    >
                      Create new account
                    </Link>
                  </p>
                ) : (
                  <></>
                )}

                {!isForgetPassword && !isRegister && (
                  <p className="small mb-5 pb-lg-2">
                    <Link
                      className="text-white-50 noUnderline"
                      to={"/forget-password"}
                    >
                      Forgot password?
                    </Link>
                  </p>
                )}

                <button
                  className="btn btn-outline-light btn-lg px-5"
                  type="submit"
                  onClick={() => loginCheck()}
                >
                  {isForgetPassword
                    ? "Submit"
                    : isRegister
                    ? "Register"
                    : "Login"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
