import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import "./Protecter.css"

function ProtecterAdmin({additionalProp , Component}) {
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      let tokenFromLocalStorage = localStorage.getItem("tokenAdmin");
      if (!tokenFromLocalStorage) {
        navigate("/login");
      } else {
        const decodedToken = jwtDecode(tokenFromLocalStorage);
        const currentTime = Date.now() / 1000; // Convert to seconds
        if (decodedToken.exp && decodedToken.exp < currentTime) {
          localStorage.clear(); // Token has expired
          navigate("/login");
        }
      }
    };
    checkToken();
  }, [navigate]);
  if (!additionalProp) {
    return (
      <div className="main">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4 pb-1 container-custom-all">
        <div className="form-group pull-right pt-4 mb-4 mx-4">
          <Component  />
        </div>
      </div>
      </div>
    );  
  }
}

export default ProtecterAdmin;
