import { Link, NavLink, useNavigate } from "react-router-dom";
function HeaderPc(props) {
    const navigate = useNavigate();
  let token = localStorage.getItem("token");
  let tokenAdmin = localStorage.getItem("tokenAdmin");
  function logout() {
    localStorage.clear();
    navigate("/login");
    return;
  }
    return ( <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
    <div className="container-fluid">
      <Link to={tokenAdmin ? "/admin" : "/"} className="navbar-brand">
        OtpSeller
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarScroll"
        aria-controls="navbarScroll"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarScroll">
        <ul
          className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll"
          style={{ bsScrollHeight: "100px" }}
        >
          <li className="nav-item">
            <NavLink
              exact="true"
              to={"/"}
              className="nav-link"
              aria-current="page"
            >
              Home
            </NavLink>
          </li>
          {tokenAdmin &&
            <>
              <li className="nav-item">
                <NavLink exact="true" to={"/admin-data"} className="nav-link">
                  Data
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact="true" to={"/admin-all-data"} className="nav-link">
                  All
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact="true" to={"/admin-sell"} className="nav-link">
                  Sell
                </NavLink>
              </li>
            </>
          }
          <li className="nav-item">
            <NavLink exact="true" to={"/country"} className="nav-link">
              Country
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact="true" to={"/contact"} className="nav-link">
              Contact us
            </NavLink>
          </li>
        </ul>
        <ul className="d-flex navbar-nav navbar-nav-scroll me-5">
          {!token && (
            <>
              <li className="nav-item me-2">
                <NavLink to={"/login"} className="nav-link">
                  Login
                </NavLink>
              </li>
              <li className="nav-item me-2">
                <NavLink to={"/register"} className="nav-link">
                  Register
                </NavLink>
              </li>
            </>
          )}
          {token && (
            <>
              <li className="nav-item me-4">
                <NavLink to={"/history"} className="nav-link">
                  History
                </NavLink>
              </li>
              <li className="nav-item me-4">
                <NavLink to={"/topup"} className="nav-link">
                  Topup
                </NavLink>
              </li>
              <li className="nav-item me-4">
                <NavLink to={"/topuphistory"} className="nav-link">
                  {props.balance} Rs.
                </NavLink>
              </li>
              <li className="nav-item dropdown ">
                <Link
                  to={"/"}
                  className="nav-link dropdown-toggle"
                  id="navbarScrollingDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {props.name}
                </Link>
                <ul
                  className="dropdown-menu"
                  style={{ right: "15px", left: "-100px" }}
                  aria-labelledby="navbarScrollingDropdown"
                >
                  <li>
                    <Link
                      to={"/api"}
                      className="dropdown-item text-center"
                      style={{ borderRadius: "20px" }}
                    >
                      Get Api Key
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/topuphistory"}
                      className="dropdown-item text-center"
                      style={{ borderRadius: "20px" }}
                    >
                      Top-up History
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <button
                      onClick={logout}
                      className="dropdown-item text-center"
                      style={{
                        backgroundColor: "darkred",
                        borderRadius: "20px",
                        color: "white",
                      }}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  </nav>
);
}

export default HeaderPc;