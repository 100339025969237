import { Link, NavLink, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import PublicIcon from "@mui/icons-material/Public";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HistoryIcon from "@mui/icons-material/History";
import AddCardIcon from "@mui/icons-material/AddCard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle"; 
function HeaderPc(props) {
  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  let tokenAdmin = localStorage.getItem("tokenAdmin");
  function logout() {
    localStorage.clear();
    navigate("/login");
    return;
  }
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <Link to={tokenAdmin ? "/admin" : "/"} className="navbar-brand iconname">
          OtpSeller
        </Link>
        <ul
          className="navbar-nav me-auto"
          style={{ bsScrollHeight: "100px", flexDirection: "row" }}
        >
          { tokenAdmin && <li className="nav-item me-2">
            <NavLink
              exact="true"
              to={"/"}
              className="nav-link"
              aria-current="page"
            >
              <HomeIcon />
            </NavLink>
          </li>}
          <li className="nav-item me-2">
            <NavLink exact="true" to={"/country"} className="nav-link">
              <PublicIcon />
            </NavLink>
          </li>
          <li className="nav-item me-2">
            <NavLink exact="true" to={"/contact"} className="nav-link">
              <ContactPageIcon />
            </NavLink>
          </li>
        </ul>
        <ul className="navbar-nav" style={{ flexDirection: "row" }}>
          {!token && (
            <>
              <li className="nav-item me-2">
                <NavLink to={"/login"} className="nav-link">
                  Login
                </NavLink>
              </li>
              <li className="nav-item me-2">
                <NavLink to={"/register"} className="nav-link">
                  Register
                </NavLink>
              </li>
            </>
          )}
          {token && (
            <>
              <li className="nav-item me-2">
                <NavLink to={"/history"} className="nav-link">
                  <HistoryIcon />
                </NavLink>
              </li>
              <li className="nav-item me-2">
                <NavLink to={"/topup"} className="nav-link">
                  <AddCardIcon />
                </NavLink>
              </li>
              <li className="nav-item me-2">
                <NavLink to={"/topuphistory"} className="nav-link">
                  {props.balance} Rs.
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <Link
                  to={"/"}
                  className="nav-link dropdown-toggle"
                  id="dropdownMenuButton1"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <AccountCircleIcon />
                </Link>
                <ul
                  className="dropdown-menu"
                  style={{ right: "0", left: "auto", position: "absolute" }}
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <Link
                      to={"/api"}
                      className="dropdown-item text-center"
                      style={{ borderRadius: "20px" }}
                    >
                      Get Api Key
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/topuphistory"}
                      className="dropdown-item text-center"
                      style={{ borderRadius: "20px" }}
                    >
                      Top-up History
                    </Link>
                  </li>
                  {tokenAdmin && (
                    <>
                      <li>
                        <Link
                          to={"/admin-data"}
                          className="dropdown-item text-center"
                          style={{ borderRadius: "20px" }}
                        >
                          Data
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={"/admin-all-data"}
                          className="dropdown-item text-center"
                          style={{ borderRadius: "20px" }}
                        >
                          All
                        </Link>
                      </li>
                      <li>
                        <Link
                          exact="true"
                          to={"/admin-sell"}
                          className="dropdown-item text-center"
                          style={{ borderRadius: "20px" }}
                        >
                          Sell
                        </Link>
                      </li>
                    </>
                  )}
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <button
                      onClick={logout}
                      className="dropdown-item text-center"
                      style={{
                        backgroundColor: "darkred",
                        borderRadius: "20px",
                        color: "white",
                      }}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default HeaderPc;
