import "./Country.css";
import { useEffect, useState } from "react";
import backgoundImage from "../assest/blank.webp";
import React from "react";
import { Link } from "react-router-dom";
import Loader from "./loader/Loader";
import Customsnackbar from "./snackbar/Customsnackbar";
import ServerCheck from "./ServerCheck";

function Country() {
  const [data, setData] = useState(null);
  const [search, setSeacrh] = useState([""]);
  const [filteredData, setFilteredData] = useState(null);
  const [open, setOpen] = useState(false);
  const [server_status , setServerStatus] = useState(true)
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    color: "", // Default color
  });

  useEffect(() => {
    const fetchData = async () => {
      let tokenFromLocalStorage = localStorage.getItem("token");
      try {
        const response = await fetch(
          `https://otpseller.store/apitest/country.php`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + tokenFromLocalStorage,
            },
          }
        );
        const jsonData = await response.json();
        setData(jsonData.msg);
        setServerStatus(jsonData.server_status)
      } catch (error) {
        setSnackbarData({
          message: `Something is not working. Try again later.`,
          color: "error",
        });
        setOpen(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // If props.searchData is not blank, filter the data
    if (typeof search === "string" && data) {
      const searchTerm = search.toLowerCase();
      const filtered = Object.keys(data).reduce((obj, key) => {
        if (typeof key === "string" && key.toLowerCase().includes(searchTerm)) {
          obj[key] = data[key];
        }
        return obj;
      }, {});
      setFilteredData(filtered);
    }
  }, [search, data]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4 pb-1 container-custom">
      <Customsnackbar
        open={open}
        onClose={handleClose}
        message={snackbarData.message}
        color={snackbarData.color}
      />
      <div className="form-group pull-right pt-4 mb-4 mx-4">
        {!data ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <input
              type="text"
              className="search form-control"
              value={search}
              placeholder="What you looking for?"
              onChange={(e) => setSeacrh(e.target.value)}
            />
            <div className="mt-4 mx-2">
              { server_status === false ? <ServerCheck / > : <></>}
              <table className="table table-striped table-bordered results">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="text-center">
                      Country
                    </th>
                    <th scope="col" className="text-center">
                      Prefix
                    </th>
                    <th scope="col" className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(filteredData || data).length === 0 ? (
                    <tr>
                      <td colSpan="3" className="text-center">
                        <b>No Country Found.</b>
                      </td>
                    </tr>
                  ) : (
                    Object.keys(filteredData || data).map((key) => {
                      const item = (filteredData || data)[key];
                      const capitalizedKey =
                        key.charAt(0).toUpperCase() + key.slice(1);
                      return (
                        <tr className="text-center align-middle" key={key}>
                          <td className="align-middle">
                            <div className="row">
                              <div className="col-12 col-md-6 text-right-md ">
                                <img
                                  className={`flags  flags-${Object.keys(
                                    item.iso || {}
                                  ).join(", ")} d-inline`}
                                  alt="otp seller number of"
                                  src={backgoundImage}
                                />
                              </div>
                              <div className="col-12 col-md-6 text-left-md pt-2">
                                {capitalizedKey}
                              </div>
                            </div>
                          </td>

                          <td className="text-center align-middle">
                            {Object.keys(item.prefix || {}).join(", ")}
                          </td>
                          <td className="text-center align-middle">
                            <Link to={`/${key}`}>
                              <button className="btn btn-primary btn-sm">
                                Select
                              </button>
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Country;
