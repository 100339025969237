import React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import { Link } from "react-router-dom";
import Loader from "./loader/Loader";
import Customsnackbar from "./snackbar/Customsnackbar";

function History() {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const isWideScreen = window.innerWidth >= 900;
  const [loading , setLoading] = useState(true)
  const [open, setOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    color: "", // Default color
  });

  useEffect(() => {
    let tokenFromLocalStorage = localStorage.getItem("token");
    setLoading(true)
    const fetchData = async () => {
      try {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const response = await fetch(
          `https://otpseller.store/apitest/history.php?start=${startIndex}&limit=${itemsPerPage}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + tokenFromLocalStorage,
            },
          }
        );
        const result = await response.json();
        setLoading(false)
        setData(result.msg);
        setTotalCount(result.totalCount);
      } catch (error) {
        setSnackbarData({
          message: `Error fetching data:${error}`,
          color: "error",
        });
        setOpen(true);
      }
    };

    fetchData();
  }, [currentPage]);

  const handleChangePage = (event,newPage) => {
    setCurrentPage(newPage);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <TableContainer component={Paper}>
      <Customsnackbar
        open={open}
        onClose={handleClose}
        message={snackbarData.message}
        color={snackbarData.color}
      />
      {loading && <Loader />}
      <Table sx={{ minWidth: 10 }} size="small"aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Order ID</StyledTableCell>

            <StyledTableCell align="center">Number</StyledTableCell>

            <StyledTableCell align="center">Product</StyledTableCell>
            <StyledTableCell align="center">Price</StyledTableCell>
            {isWideScreen && (
              <>
                <StyledTableCell align="center">Country</StyledTableCell>
                <StyledTableCell align="center">Balance Left</StyledTableCell>
                <StyledTableCell align="center">Time</StyledTableCell>
              </>
            )}
            <StyledTableCell align="center">Check</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <StyledTableRow key={row.sno}>
              <StyledTableCell align="center" component="th" scope="row">
                {row.id}
              </StyledTableCell>
              <StyledTableCell align="center">{row.phone}</StyledTableCell>
              <StyledTableCell align="center">{row.product}</StyledTableCell>
              <StyledTableCell className="text-danger" align="center">-{row.price}</StyledTableCell>
              {isWideScreen && (
                <>
                  <StyledTableCell align="center">
                    {row.country}
                  </StyledTableCell>
                  <StyledTableCell className="text-success" align="center">{row.bl}</StyledTableCell>
                  <StyledTableCell align="center">{row.time}</StyledTableCell>
                </>
              )}
              <StyledTableCell align="center">
                <Link to={`/order/${row.id}`}>
                <button type="button" className="btn btn-primary btn-sm">Check</button>
                </Link>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px 0px",
        }}
      >
        <Pagination
          count={Math.ceil(totalCount / itemsPerPage)} // Assuming the API provides the total count
          page={currentPage}
          onChange={handleChangePage}
          shape="rounded"
          style={{ justifyContent: "flex-end" }}
        />
      </div>
    </TableContainer>
  );
}

export default History;
