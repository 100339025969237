import React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Loader from "./loader/Loader";
import Grid from "@mui/material/Grid";
import Customsnackbar from "./snackbar/Customsnackbar";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import Button from "@mui/material/Button";
import { TextField, Autocomplete, Box } from "@mui/material";
const options = ["id_otp1", "id5", "phone", "operator", "product", "country"];
function AdminSell() {
  const [data, setData] = useState([]);
  const [sell, setSell] = useState();
  const [totalCount, setTotalCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [searchitem, setSearchitem] = useState(0);
  const [searchtype, setSearchtype] = useState("id_otp1");
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    color: "", // Default color
  });

  const fetchData = async (searchtype, searchitem) => {
    setLoading(true);
    let tokenFromLocalStorage = localStorage.getItem("tokenAdmin");
    try {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const response = await fetch(
        `https://otpseller.store/apitest/selling.php?start=${startIndex}&limit=${itemsPerPage}&searchitem=${searchitem}&searchtype=${searchtype}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenFromLocalStorage,
          },
        }
      );
      const result = await response.json();
      setLoading(false);
      if (response.status === 200) {
        setData(result.msg.data);
        setSell(result.msg.totalAmount);
        setTotalCount(result.msg.totalCount);
      } else {
        setSnackbarData({
          message: result.msg || "Unknown error", // Handle potential error message
          color: "error",
        });
        setOpen(true);
      }
    } catch (error) {
      setSnackbarData({
        message: `ERROR: ${error}`,
        color: "error",
      });
      setOpen(true);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(searchtype, searchitem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item>
          Total Amount :&nbsp;
          {sell ? (
            <span style={{ color: "green" }}>
              {sell.toLocaleString("en-IN")}
            </span>
          ) : (
            <span style={{ color: "red" }}>N/A</span>
          )}{" "}
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Autocomplete
          autoSelect
          value={searchtype}
          onChange={(e, newValue) => setSearchtype(newValue)}
          options={options}
          sx={{ width: 150, marginRight: "8px" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Data Type"
              variant="standard"
              margin="normal"
            />
          )}
        />
        <TextField
          label="Search"
          variant="standard"
          margin="normal"
          onChange={(e) => setSearchitem(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{margin:"15px"}}
          onClick={() => fetchData(searchtype, searchitem)}
        >
          Search
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Customsnackbar
          open={open}
          onClose={handleClose}
          message={snackbarData.message}
          color={snackbarData.color}
        />
        {loading && <Loader />}
        <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">S.no</StyledTableCell>
              <StyledTableCell align="center">id5</StyledTableCell>
              <StyledTableCell align="center">phone</StyledTableCell>
              <StyledTableCell align="center">product</StyledTableCell>
              <StyledTableCell align="center">price</StyledTableCell>
              <StyledTableCell align="center">Time</StyledTableCell>
              <StyledTableCell align="center">id_otp1</StyledTableCell>
              <StyledTableCell align="center">action</StyledTableCell>
              <StyledTableCell align="center">Balance_left</StyledTableCell>
              <StyledTableCell align="center">otpbalance</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data ? (
              data.map((row) => (
                <StyledTableRow key={row.sno}>
                  <StyledTableCell align="center" component="th" scope="row">
                    {row.sno}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.id5}</StyledTableCell>
                  <StyledTableCell align="center">{row.phone}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.product}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.price}</StyledTableCell>
                  <StyledTableCell align="center">{row.time}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.id_otp1}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.action === "Complete" ? (
                      <VerifiedRoundedIcon style={{ color: "green" }} />
                    ) : row.action === "Ban" ? (
                      <BlockRoundedIcon style={{ color: "red" }} />
                    ) : (
                      <ClearRoundedIcon style={{ color: "gray" }} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.Balance_left}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.otpbalance}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={5} align="center">
                  No data available
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px 0px",
          }}
        >
          <Pagination
            count={Math.ceil(totalCount / itemsPerPage)} // Assuming the API provides the total count
            page={currentPage}
            onChange={handleChangePage}
            shape="rounded"
            style={{ justifyContent: "flex-end" }}
          />
        </div>
      </TableContainer>
    </>
  );
}

export default AdminSell;
