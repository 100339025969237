import React, { useState } from "react";
import "./Login.css";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./loader/Loader";
import Customsnackbar from "./snackbar/Customsnackbar";

function ResetPassword() {
  const { vs_code, email } = useParams();
  
  const [password, setPassword] = useState("");
  const [passwordTwo, setpasswordTwo] = useState("");

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    color: "", // Default color
  });
  const navigate = useNavigate();



  async function changePassword() {
      if (password.length === 0 || passwordTwo.length === 0) {
        setSnackbarData({
          message: "Email cannot be null",
          color: "error",
        });
        setOpen(true);
        return;
      }else if (password !== passwordTwo) {
        setSnackbarData({
          message: "Password and Confirm Password cannot match",
          color: "error",
        });
        setOpen(true);
        return;
      }else if (password.length < 6) {
        setSnackbarData({
          message: "Password is weak",
          color: "error",
        });
        setOpen(true);
        return;
      }
      try {
        setLoading(true);
        const response = await fetch(
          "https://otpseller.store/apitest/reset_pass.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ password , vs_code , email }),
          }
        );
        const dataReceived = await response.json();
        setLoading(false);
        if (dataReceived.status === 200) {
          setSnackbarData({
            message: dataReceived.msg,
            color: "success",
          });
          setOpen(true);
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        } else {
            setSnackbarData({
              message: dataReceived.msg,
              color: "error",
            });
            setOpen(true);
          }
      } catch (error) {
        setLoading(false);
        setSnackbarData({
          message: "Something went wrong",
          color: "error",
        });
        setOpen(true);
        setpasswordTwo("");
        setPassword("");
      }
    }



    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setOpen(false);
      };



  return (
    <>
      <section className="mb-3 mt-3 gradient-custom main">
      <Customsnackbar
        open={open}
        onClose={handleClose}
        message={snackbarData.message}
        color={snackbarData.color}
      />
      {loading && <Loader />}
        <div
          className="row justify-content-center align-items-center"
          style={{ marginRight: "0" }}
        >
          <div className="col-11 col-md-6 col-lg-6 col-xl-5">
            <div
              className="card bg-dark text-white"
              style={{ borderRadius: "1rem" }}
            >
              <div className="card-body p-5 text-center">
                <div className="mb-md-4">
                  <h2 className="fw-bold mb-2 text-uppercase">
                    Reset Password
                  </h2>
                  <p className="text-white-50 mb-4">
                    Please enter password!
                  </p>
                  <div className="form-outline form-white mb-4">
                    <label className="form-label" htmlFor="typePasswordX">
                      Password
                    </label>
                    <input
                      type="password"
                      id="typePasswordX"
                      className="form-control form-control-lg"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </div>
                  <div className="form-outline form-white mb-4">
                    <label className="form-label" htmlFor="typePasswordX">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      onChange={(e) => setpasswordTwo(e.target.value)}
                      value={passwordTwo}
                    />
                  </div>
                  <button
                    className="btn btn-outline-light btn-lg px-5"
                    type="submit"
                    onClick={() => changePassword()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ResetPassword;
