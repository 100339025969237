import React from "react";
import HeaderPc from "./HeaderPc";
import HeaderMobile from "./HeaderMobile";

function Header(props) {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {windowWidth < 992 ? <HeaderMobile balance={props.balance} name={props.name} /> : <HeaderPc balance={props.balance} name={props.name} />}
    </div>
  );
}

export default Header;
