import React from "react";
import './ApiCall.css'
import ApiCallApi from "./ApiCallApi";
import rightbtn from "../assest/rightbtn.svg";
function ApiCall() {
    const token = localStorage.getItem('token')

function handleCopy() {
    const textarea = document.createElement('textarea');
        textarea.value = token;
        // Append the textarea to the body
        document.body.appendChild(textarea);

        // Select the token inside the textarea
        textarea.select();

        // Copy the selected text
        document.execCommand('copy');

        // Remove the textarea from the DOM
        document.body.removeChild(textarea);
}
  return (
    <div>
      <h2 className="text-center">Api Key</h2>
      <div className="mui-style-1bblrdb">
        <p className="MuiTypography-root MuiTypography-body1 mui-style-7j43ue">
          <span className="mui-style-aonval">{token}</span>
        </p>
        <button
          className="mui-style-1mymdw7"
          tabIndex="0"
          type="button"
          aria-label="Copy"
        >
          <img
            alt="Copy"
            loading="lazy"
            width="24"
            height="24"
            decoding="async"
            data-nimg="1"
            onClick={handleCopy}
            src={rightbtn}
            style={{color: 'transparent'}}
          />
          {/* <span className="mui-style-w0pj6f"></span> */}
        </button>
      </div>
      <div style={{marginTop:'20px'}}>
      <ApiCallApi />
      </div>
    </div>
  );
}

export default ApiCall;
