import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import backgoundImage from "../assest/blank.webp";
import Loader from "./loader/Loader";
import Customsnackbar from "./snackbar/Customsnackbar";
import ServerCheck from "./ServerCheck";
import "./Type.css";
function Type({ onbalance }) {
  const params = useParams();
  const { country, product } = params;
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const capitalizedCountry = country.charAt(0).toUpperCase() + country.slice(1);
  const capitalizedProduct = product.charAt(0).toUpperCase() + product.slice(1);
  const [open, setOpen] = useState(false);
  const [server_status , setServerStatus] = useState(true)
  
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    color: "", // Default color
  });

  const handlebalance = (cost) => {
    onbalance(cost);
  };

  useEffect(() => {
    const fetchData = async () => {
      let tokenFromLocalStorage = localStorage.getItem('token')
      try {
        const response = await fetch(
          `https://otpseller.store/apitest/type.php?buyid=${product}&cn=${country}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + tokenFromLocalStorage,
            },
          }
        );
        const jsonData = await response.json();
        if (parseInt(jsonData.status) === 200) {
          setData(jsonData.msg);
          setServerStatus(jsonData.server_status)
        } else{
          setSnackbarData({
            message: jsonData.msg,
            color: "error",
          });
          setOpen(true);
        }
        setLoading(false);
      } catch (error) {
        setSnackbarData({
          message: `Something is not wrong. Try again later.`,
          color: "error",
        });
        setOpen(true);
      }
    };

    fetchData();
  }, [product, country]);

  const buyProduct = async (country, product, virtual, cost) => {
    let tokenFromLocalStorage = localStorage.getItem("token");
    try {
      setLoading(true);
      const response = await fetch(
        `https://otpseller.store/apitest/buyorder.php?buyid=${product}&services=${virtual}&cn=${country}&price=${cost}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenFromLocalStorage,
          },
        }
      );
      const data = await response.json();
      setLoading(false);
      if (response.ok) {
        if (data.status !== 400) {
          handlebalance(-cost);
          navigate(`/order/${data.msg}`);
        } else {
          setSnackbarData({
            message: data.msg,
            color: "error",
          });
          setOpen(true);
        }
      } else {
        setSnackbarData({
          message: data.msg,
          color: "error",
        });
        setOpen(true);
      }
    } catch (error) {
      setSnackbarData({
        message: `Something is not wrong. Try again later.`,
        color: "error",
      });
      setOpen(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="mx-4 mb-4">
      <Customsnackbar
        open={open}
        onClose={handleClose}
        message={snackbarData.message}
        color={snackbarData.color}
      />
      {loading && <Loader />}
      <img
        className={`products products-${product} d-inline mb-2`}
        src={backgoundImage}
        alt={`otp seller number of ${capitalizedProduct}`}
      />
      <h3 className="mx-4 d-inline">
        {capitalizedProduct} Otp Buy :- {capitalizedCountry}
      </h3>
      <hr></hr>
      { server_status === false ? <ServerCheck / > : <></>}
      <table className="table table-striped">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3 text-center">
              Services
            </th>
            <th scope="col" className="px-6 py-3 text-center">
              Cost
            </th>
            <th scope="col" className="px-6 py-3 text-center">
              Count
            </th>
            <th scope="col" className="px-6 py-3 text-center">
              Buy
            </th>
          </tr>
        </thead>
        <tbody>
          {data && (
            <>
              {Object.entries(data).map(([country, operators]) =>
                Object.keys(operators).map((product) =>
                  Object.keys(operators[product]).map((virtual) => (
                    <tr key={virtual}>
                      <td className="text-center">{virtual}</td>
                      <td className="text-center">
                        {operators[product][virtual].cost}
                      </td>
                      <td className="text-center">
                        {operators[product][virtual].count}
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() =>
                            buyProduct(
                              country,
                              product,
                              virtual,
                              operators[product][virtual].cost
                            )
                          }
                        >
                          Buy Now
                        </button>
                      </td>
                    </tr>
                  ))
                )
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Type;
