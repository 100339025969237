import React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { red } from "@mui/material/colors";
import Loader from "./loader/Loader";
import Grid from "@mui/material/Grid";
import Customsnackbar from "./snackbar/Customsnackbar";
import Button from "@mui/material/Button";
import { TextField, Autocomplete, Box } from "@mui/material";

const options = ["id_opt1", "id", "name", "address", "field", "amount"];
function Admin() {
  const [data, setData] = useState([]);
  const [sell, setSell] = useState([]);
  const [profit, setProfit] = useState([]);
  const [Total_Profit, setTotal_Profit] = useState([]);
  const [Total_sell, setTotal_sell] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchitem, setSearchitem] = useState(0);
  const [searchtype, setSearchtype] = useState("id_opt1");
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    color: "", // Default color
  });

  const fetchData = async (searchtype, searchitem) => {
    setLoading(true);
    let tokenFromLocalStorage = localStorage.getItem("tokenAdmin");
    try {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const response = await fetch(
        `https://otpseller.store/apitest/admin.php?start=${startIndex}&limit=${itemsPerPage}&searchitem=${searchitem}&searchtype=${searchtype}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenFromLocalStorage,
          },
        }
      );
      const result = await response.json();
      setLoading(false);
      if (response.status === 200) {
        setData(result.msg.data);
        setSell(result.msg.sell);
        setProfit(result.msg.profit);
        setTotal_sell(result.msg.Total_sell);
        setTotal_Profit(result.msg.Total_Profit);
        setTotalCount(result.msg.totalCount);
      } else {
        setSnackbarData({
          message: result.msg || "Unknown error", // Handle potential error message
          color: "error",
        });
        setOpen(true);
      }
    } catch (error) {
      setSnackbarData({
        message: `ERROR: ${error}`,
        color: "error",
      });
      setOpen(true);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(searchtype, searchitem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  async function addMoney(idno, amount, sno) {
    let tokenFromLocalStorage = localStorage.getItem("tokenAdmin");
    setLoading(true);
    try {
      const response = await fetch(
        `https://otpseller.store/apitest/addmoney.php?id=${idno}&id1=${sno}&amount=${amount}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenFromLocalStorage,
          },
        }
      );
      const result = await response.json();
      setLoading(false);
      if (response.status === 200) {
        setSnackbarData({
          message: result.msg,
          color: "success",
        });
        setOpen(true);
      } else {
        setSnackbarData({
          message: result.msg || "Unknown error", // Handle potential error message
          color: "error",
        });
        setOpen(true);
      }
      fetchData(searchtype, searchitem);
    } catch (error) {
      setSnackbarData({
        message: `ERROR: ${error}`,
        color: "error",
      });
      setOpen(true);
    }
  }
  async function cancelMoney(idno, sno) {
    let tokenFromLocalStorage = localStorage.getItem("tokenAdmin");
    setLoading(true);

    try {
      const response = await fetch(
        `https://otpseller.store/apitest/addmoney.php?id=${idno}&id1=${sno}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenFromLocalStorage,
          },
        }
      );
      const result = await response.json();
      setLoading(false);
      if (response.status === 200) {
        setSnackbarData({
          message: result.msg,
          color: "success", // Fix the typo in "success"
        });
        setOpen(true);
      } else {
        setSnackbarData({
          message: result.msg || "Unknown error", // Handle potential error message
          color: "error",
        });
        setOpen(true);
      }
      fetchData(searchtype, searchitem);
    } catch (error) {
      setSnackbarData({
        message: `ERROR: ${error.message || error}`,
        color: "error",
      });
      setOpen(true);
    }
  }

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item>
          Sell :{" "}
          <span style={{ color: "red" }}>{sell.toLocaleString("en-IN")}</span>{" "}
        </Grid>
        <Grid item>
          Profit :{" "}
          <span style={{ color: "green" }}>
            {profit.toLocaleString("en-IN")}
          </span>
        </Grid>
        <Grid item>
          Total_sell :{" "}
          <span style={{ color: "darkviolet" }}>
            {Total_sell.toLocaleString("en-IN")}
          </span>
        </Grid>
        <Grid item>
          Total_Profit :
          <span style={{ color: "fuchsia" }}>
            {Total_Profit.toLocaleString("en-IN")}
          </span>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Autocomplete
          autoSelect
          value={searchtype}
          onChange={(e, newValue) => setSearchtype(newValue)}
          options={options}
          sx={{ width: 150, marginRight: "8px" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Data Type"
              variant="standard"
              margin="normal"
            />
          )}
        />
        <TextField
          label="Search"
          variant="standard"
          margin="normal"
          onChange={(e) => setSearchitem(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{ margin: "15px" }}
          onClick={() => fetchData(searchtype, searchitem)}
        >
          Search
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Customsnackbar
          open={open}
          onClose={handleClose}
          message={snackbarData.message}
          color={snackbarData.color}
        />
        {loading && <Loader />}
        <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Amount</StyledTableCell>
              <StyledTableCell align="center">idno</StyledTableCell>
              <StyledTableCell align="center">S.no</StyledTableCell>
              <StyledTableCell align="center">Utr Id</StyledTableCell>
              <StyledTableCell align="center">Code</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <StyledTableRow key={row.sno}>
                <StyledTableCell align="center">
                  {row.status === "done" || row.status === "Done" ? (
                    <CheckCircleIcon color="success" />
                  ) : row.status === "Failed" ? (
                    <CancelIcon sx={{ color: red[600] }} />
                  ) : (
                    <Grid container spacing={1}>
                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<CheckCircleIcon />}
                          color="success"
                          onClick={() =>
                            addMoney(row.idno, row.amount, row.sno)
                          }
                        >
                          Approve
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          size="small"
                          variant="contained"
                          startIcon={<CancelIcon />}
                          color="error"
                          onClick={() => cancelMoney(row.idno, row.sno)}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">{row.name}</StyledTableCell>
                <StyledTableCell align="center">{row.amount}</StyledTableCell>
                    <StyledTableCell align="center">{row.idno}</StyledTableCell>
                    <StyledTableCell align="center" component="th" scope="row">
                      {row.sno}
                    </StyledTableCell>
                <StyledTableCell align="center">{row.field}</StyledTableCell>
                <StyledTableCell align="center">{row.code}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "10px 0px",
          }}
        >
          <Pagination
            count={Math.ceil(totalCount / itemsPerPage)} // Assuming the API provides the total count
            page={currentPage}
            onChange={handleChangePage}
            shape="rounded"
            style={{ justifyContent: "flex-end" }}
          />
        </div>
      </TableContainer>
    </>
  );
}

export default Admin;
