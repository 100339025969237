import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Customsnackbar from "./components/snackbar/Customsnackbar";
import Protecter from "./header/Protecter";
import Header from "./header/Header";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Country from "./components/Country";
import TopHistory from "./components/TopHistory";
import Topup from "./components/Topup";
import History from "./components/History";
import Login from "./components/Login";
import Type from "./components/Type";
import Order from "./components/Order";
import Footer from "./header/Footer";
import Rechage from "./components/Rechage";
import ResetPassword from "./components/ResetPassword";
import ProtecterAdmin from "./header/ProtecterAdmin";
import Admin from "./components/Admin";
import AdminData from "./components/AdminData";
import AdminAllData from "./components/AdminAllData";
import AdminSell from "./components/AdminSell";
import ApiCall from "./components/ApiCall";

function App() {
  const [balance, setBalance] = useState(0);
  const [name, setName] = useState([]);
  const [open, setOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    color: "", // Default color
  });

  const updateBalance = (cost) => {
    const updatedBalance = (parseFloat(balance) + parseFloat(cost)).toFixed(2);
    setBalance(updatedBalance);
  };
  const [count, setCount] = useState(true);
  useEffect(() => {
    let timeoutId;

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        timeoutId = setTimeout(() => {
          document.title = "Refreshing...";
          setCount(false);
        }, 10 * 60 * 2000);
      } else {
        clearTimeout(timeoutId);
        document.title = "Otp Seller";
        if (count === false) {
          window.location.reload();
          setCount(true);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearTimeout(timeoutId);
    };
  }, [count]);

  useEffect(() => {
    let tokenFromLocalStorage = localStorage.getItem("token");
    if (tokenFromLocalStorage) {
      const checkToken = async () => {
        try {
          const response = await fetch(
            "https://otpseller.store/apitest/tokencheck.php",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + tokenFromLocalStorage,
              },
            }
          );
          if (response.ok) {
            const jsonData = await response.json(); 
            setBalance(jsonData.balance);
            setName(jsonData.name);
            setSnackbarData({
              message: `Welcome ${jsonData.name}`,
              color: "success",
            });
            setOpen(true);
          }
          // }
        } catch {
          setSnackbarData({
            message: `Something is wrong.`,
            color: "error",
          });
          setOpen(true);
        }
      };
      checkToken();
    }
  }, [ ]);
  function handlebalanceapi(balance, name) {
    setBalance(balance);
    setName(name);
    setSnackbarData({
      message: `Welcome ${name}`,
      color: "success",
    });
    setOpen(true);
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <Routes>
        {/* Include Header inside a Route to ensure it has access to router context */}
        <Route
          path="/*"
          element={
            <>
              <Customsnackbar
                open={open}
                onClose={handleClose}
                message={snackbarData.message}
                color={snackbarData.color}
              />
              <Header balance={balance} name={name} />
              <Routes>
                <Route
                  path="/login"
                  element={<Login onbalnaceapi={handlebalanceapi} />}
                />
                <Route
                  path="/register"
                  element={<Login onbalnaceapi={handlebalanceapi} />}
                />
                <Route
                  path="/forget-password"
                  element={<Login onbalnaceapi={handlebalanceapi} />}
                />
                <Route
                  path="/reset_password/:email/:vs_code"
                  element={<ResetPassword />}
                />
                <Route
                  path="/country"
                  element={
                    <Protecter
                      Component={Country}
                      additionalProp="showsearch"
                    />
                  }
                />
                <Route
                  path="/topuphistory"
                  element={<Protecter Component={TopHistory} />}
                />
                <Route
                  path="/api"
                  element={<Protecter Component={ApiCall} />}
                />
                <Route
                  path="/admin"
                  element={<ProtecterAdmin Component={Admin} />}
                />
                <Route
                  path="/admin-data"
                  element={<ProtecterAdmin Component={AdminData} />}
                />
                <Route
                  path="/admin-all-data"
                  element={<ProtecterAdmin Component={AdminAllData} />}
                />
                <Route
                  path="/admin-sell"
                  element={<ProtecterAdmin Component={AdminSell} />}
                />
                <Route
                  path="/topup"
                  element={<Protecter Component={Topup} balance={balance} />}
                />
                <Route
                  path="/rechage/:amount"
                  element={<Protecter Component={Rechage} />}
                />
                <Route
                  path="/history"
                  element={<Protecter Component={History} />}
                />
                <Route
                  path="/contact"
                  element={<Protecter Component={Contact} />}
                />
                <Route
                  path="/type/:country/:product"
                  element={
                    <Protecter
                      Component={Type}
                      onAgainBalance={updateBalance}
                    />
                  }
                />
                <Route
                  path="/order/:id"
                  element={
                    <Protecter
                      Component={Order}
                      onAgainBalance={updateBalance}
                    />
                  }
                />
                <Route
                  path="/:country"
                  element={
                    <Protecter Component={Home} additionalProp="showsearch" />
                  }
                />
                <Route
                  path="/"
                  element={
                    <Protecter Component={Home} additionalProp="showsearch" />
                  }
                />
              </Routes>
              <Footer />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
