import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const Customsnackbar = ({ open, onClose, message, color }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        severity={color}
        variant="filled"
        onClose={onClose}
        sx={{ minWidth: 200, width: 'auto' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Customsnackbar;
