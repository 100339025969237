import React from "react";
function ServerCheck() {
  return (
    <div
      style={{ fontWeight: "600", color: "red" }}
      className="alert alert-primary"
      role="alert"
    >
      Apologies, server is currently unavailable. Please try again later. Thank
      you for your patience.
    </div>
  );
}

export default ServerCheck;
