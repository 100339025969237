import React from "react";
import "./Footer.css"

function Footer() {
  return (
      <div className="foot">
        <span>Copyright &copy; 2023 </span>
        <span> otpseller. All rights reserved.</span>
      </div>
  );
}

export default Footer;
