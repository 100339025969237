import React from "react";
import backgoundImage from "../assest/blank.webp";
import './Home.css';

function OrderComp(props) {
    const { label, value } = props;
  return (
    <div className="datadiv">
      <p className="firstp">{label}</p>
      <hr className="gap1" />
      <p className="secondp">
      {label === "Product" && (
        <img
          className={`products products-${value} d-inline`}
          src={backgoundImage}
          alt={`otp seller number of ${value}`}
        />
      )}
        {value}
      </p>
    </div>
  );
}
export default OrderComp;
