import React, { useState } from "react";
import "./Topup.css";
import TopupAddAmount from "./TopupAddAmount";
import Input from "@mui/material/Input";
import StepValue from "./stepValue/StepValue"
import { useNavigate } from "react-router-dom";
function Topup({balance}) {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    // Allow only numeric characters
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    setValue(numericValue);
  };
  const submitForm = (value) => {
    navigate(`/rechage/${value}`)
  };
  return (
    <>
    <StepValue StepValues={0}/>
      <form action="" method="post">
        <div className="text-center">
          <h5 className="pt-3">Balance</h5>
          <h4 className="pb-3">
            <b>{balance} Rs.</b>
          </h4>
        </div>
        <div className="pb-3">
          <h3 className="ml-4">Amount</h3>
          <div className="form-group">
            <Input
              placeholder="10 ~ 50,000"
              style={{ fontSize: "30px" }}
              value={value}
              name="amount"
              onChange={handleInputChange}
              inputProps={{ maxLength: 5 }}
            />
          </div>
        </div>
        <div className="row">
          <TopupAddAmount amount={"10"} onClick={() => setValue("10")} />
          <TopupAddAmount amount={"50"} onClick={() => setValue("50")} />
          <TopupAddAmount amount={"100"} onClick={() => setValue("100")} />
          <TopupAddAmount amount={"200"} onClick={() => setValue("200")} />
          <TopupAddAmount amount={"300"} onClick={() => setValue("300")} />
          <TopupAddAmount amount={"500"} onClick={() => setValue("500")} />
        </div>
        <div className="pa-0 mt-2 mb-2 text-center pb-3">
          <button
            type="button"
            className="btn btn-primary btn-block rounded-pill font1"
            style={{ fontSize: "20px" }}
            onClick={() => submitForm(value)}
          >
            Recharge
          </button>
        </div>
      </form>
    </>
  );
}

export default Topup;
