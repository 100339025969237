import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = ["Select Amount", "Pay & Submit Form"];

export default function StepValue({StepValues}) {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={StepValues} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
