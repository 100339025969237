import React from "react";

function TopupAddAmount({amount,onClick}) {
  return (
    <div className="col-4 text-center">
      <div className="add-amt" onClick={onClick}>
        {amount}
      </div>
    </div>
  );
}

export default TopupAddAmount;
