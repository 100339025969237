import { useEffect, useState } from "react";
import backgoundImage from "../assest/blank.webp";
import "./Home.css";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./loader/Loader";
import Customsnackbar from "./snackbar/Customsnackbar";
import ServerCheck from "./ServerCheck";
function Home(props) {
  const params = useParams();
  const { country } = params;
  const [data, setData] = useState(null);
  const [search, setSeacrh] = useState([""]);
  const [filteredData, setFilteredData] = useState(null);
  const navigate = useNavigate();
  const isWideScreen = window.innerWidth >= 450;
  const defaultCountry = country || "india";
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [serverStatus, setServerStatus] = useState(true);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    color: "", // Default color
  });

  useEffect(() => {
    const fetchData = async () => {
      let tokenFromLocalStorage = localStorage.getItem("token");
      try {
        const response = await fetch(
          `https://otpseller.store/apitest/index.php/?cn=${defaultCountry}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + tokenFromLocalStorage,
            },
          }
        );
        setLoading(false);
        if (!response.ok) {
          setSnackbarData({
            message: `Something is not wrong. Try again later.`,
            color: "error",
          });
          setOpen(true);
        } else {
          const jsonData = await response.json();
          setData(jsonData.msg);
          setServerStatus(jsonData.server_status);
        }
      } catch (error) {
        setSnackbarData({
          message: `Something is not wrong. Try again later.`,
          color: "error",
        });
        setOpen(true);
      }
    };

    fetchData();
  }, [defaultCountry]); // Add props.searchData as a dependency to trigger useEffect when it changes

  useEffect(() => {
    // If props.search is not blank, filter the data
    if (typeof search === "string" && data) {
      const searchTerm = search.toLowerCase();
      const filtered = Object.keys(data).reduce((obj, key) => {
        if (typeof key === "string" && key.toLowerCase().includes(searchTerm)) {
          obj[key] = data[key];
        }
        return obj;
      }, {});
      setFilteredData(filtered);
    }
  }, [search, data]);

  function selectOperator(country, key) {
    navigate(`/type/${country}/${key}`);
  }
  const formGroupClass = `form-group ${
    isWideScreen ? "mx-4" : ""
  } pull-right pt-4 mb-4`;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4 pb-1 container-custom">
      <div className={formGroupClass}>
        <Customsnackbar
          open={open}
          onClose={handleClose}
          message={snackbarData.message}
          color={snackbarData.color}
        />
        {loading && <Loader />}
        <input
          type="text"
          className="search form-control"
          value={search}
          placeholder="What you looking for?"
          onChange={(e) => setSeacrh(e.target.value)}
        />
        <div className="mt-4 mx-2">
          {serverStatus === false ? <ServerCheck /> : <></>}
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th scope="col" className="text-center">
                  Product
                </th>
                <th scope="col" className="text-center">
                  Price Per Number
                </th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <>
                {!data ? (
                  <></>
                ) : (
                  <>
                    {Object.keys(filteredData || data).length === 0 && (data.other || data.other2) ? (
                      <>
                        <tr>
                          <td colSpan="3" className="text-center">
                            <b>
                              Service on your request is not found, try Other.
                              Accepts SMS from any website and app.
                            </b>
                          </td>
                        </tr>
                        <tr className="text-center align-middle" key="other">
                          <td className="text-center align-middle">
                            <div>
                              <div>
                                <img
                                  className={`products products-other`}
                                  src={backgoundImage}
                                  alt="otp seller number of"
                                />
                              </div>
                              Other
                            </div>
                          </td>
                          <td className="text-center align-middle">
                            {data.other.Price} Rs. / number
                          </td>
                          <td className="text-center align-middle">
                            <button
                              onClick={() =>
                                selectOperator(defaultCountry, "other")
                              }
                              className="btn btn-primary btn-sm"
                            >
                              Buy Now
                            </button>
                          </td>
                        </tr>
                        {data.Other2 ? (
                          <tr className="text-center align-middle" key="Other2">
                            <td className="text-center align-middle">
                              <div>
                                <div>
                                  <img
                                    className={`products products-Other2`}
                                    src={backgoundImage}
                                    alt="otp seller number of"
                                  />
                                </div>
                                Other2
                              </div>
                            </td>
                            <td className="text-center align-middle">
                              {data.Other2.Price} Rs. / number
                            </td>
                            <td className="text-center align-middle">
                              <button
                                onClick={() =>
                                  selectOperator(defaultCountry, "Other2")
                                }
                                className="btn btn-primary btn-sm"
                              >
                                Buy Now
                              </button>
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      Object.keys(filteredData || data).map((key) => {
                        const item = (filteredData || data)[key];
                        const capitalizedKey =
                          key.charAt(0).toUpperCase() + key.slice(1);

                        return (
                          <tr className="text-center align-middle" key={key}>
                            <td className="text-center align-middle">
                              <div>
                                <div>
                                  <img
                                    className={`products products-${key}`}
                                    src={backgoundImage}
                                    alt="otp seller number of"
                                  />
                                </div>
                                {capitalizedKey}
                              </div>
                            </td>
                            <td className="text-center align-middle">
                              {item.Price} Rs. / number
                            </td>
                            <td className="text-center align-middle">
                              <button
                                onClick={() =>
                                  selectOperator(defaultCountry, key)
                                }
                                className="btn btn-primary btn-sm"
                              >
                                Buy Now
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </>
                )}
              </>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Home;
