import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ApiCallApi() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Buy Number</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <code>
              https://otpseller.store/apitest/buyorder.php?buyid=$Product&services=$Services&cn=$Country&price=$Amount
            </code>
            <br></br>
            <br></br>
            <b>$Product</b> :- facebook , discord , amazon etc
            <br></br>
            <br></br>
            <b>$Services</b> :- virtual21 , virtual4 etc
            <br></br>
            <br></br>
            <b>$Country</b> :- india , usa , russia etc
            <br></br>
            <br></br>
            Response : &nbsp;
            <code>"msg":Order_Id,"status":200</code>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Order Check</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <code>https://otpseller.store/apitest/order.php?id=Order_id</code>
            <br></br>
            <br></br>
            <b>$Order_Id</b> :- 58494948, 544896151 ,48948451 etc
            <br></br>
            <br></br>
            Response :
            <code>
              {'"msg": {\n' +
                '  "id":585993551,\n' +
                '  "phone":"+919302044818",\n' +
                '  "operator":"virtual21",\n' +
                '  "product":"1688",\n' +
                '  "price":5.8,\n' +
                '  "status":"RECEIVED",\n' +
                '  "expires":"2024-04-14 00:09",\n' +
                '  "sms": [],\n' +
                '  "created_at":"2024-04-13 23:49",\n' +
                '  "country":"india"\n' +
                " },\n" +
                '"status":200'}
            </code>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Ban Number</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <code>https://otpseller.store/apitest/order.php?id=Order_id</code>
            <br></br>
            <br></br>
            <b>$Order_Id</b> :- 58494948, 544896151 ,48948451 etc
            <br></br>
            <br></br>
            Response :
            <code>
              {'"msg": {\n' +
                '  "id":585993551,\n' +
                '  "phone":"+919302044818",\n' +
                '  "operator":"virtual21",\n' +
                '  "product":"1688",\n' +
                '  "price":5.8,\n' +
                '  "status":"BANNED",\n' +
                '  "expires":"2024-04-14 00:09",\n' +
                '  "sms": [],\n' +
                '  "created_at":"2024-04-13 23:49",\n' +
                '  "country":"india"\n' +
                " },\n" +
                '"status":200'}
            </code>
            <br></br>
            <br></br>
            After Ban if user try to ban again this msg shows
            <br></br>
            <code>"msg": "Already Banned or Error", "status": 400</code>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
