import React from 'react';
import { Link } from 'react-router-dom';

function Contact() {
    return (
        <div className="container-fluid mt-2 pt-2">
            <div className="container mt-2 pt-2">
                <h2> Contact us on Telegram : 
                     <Link to="https://t.me/otpsellershop1" style={{textDecoration:'None'}} target="_blank" rel="noopener noreferrer"> Click here </Link>
                </h2>
            </div>
        </div>
    );
    
};

export default Contact;