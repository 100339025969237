import React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { red } from "@mui/material/colors";
import PendingIcon from "@mui/icons-material/Pending";
import Loader from "./loader/Loader";
import Customsnackbar from "./snackbar/Customsnackbar";

function TopHistory() {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const isWideScreen = window.innerWidth >= 550;
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    color: "", // Default color
  });

  useEffect(() => {
    let tokenFromLocalStorage = localStorage.getItem("token");
    setLoading(true)
    const fetchData = async () => {
      try {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const response = await fetch(
          `https://otpseller.store/apitest/topuphistory.php?start=${startIndex}&limit=${itemsPerPage}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + tokenFromLocalStorage,
            },
          }
        );
        const result = await response.json();
        setLoading(false)
        setData(result.msg);
        setTotalCount(result.totalCount);
      } catch (error) {
        setSnackbarData({
          message: `ERROR: ${error}`,
          color: "error",
        });
        setOpen(true);
      }
    };

    fetchData();
  }, [currentPage]);

  const handleChangePage = (event,newPage) => {
    setCurrentPage(newPage);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <TableContainer component={Paper}>
      <Customsnackbar
        open={open}
        onClose={handleClose}
        message={snackbarData.message}
        color={snackbarData.color}
      />
    {loading && <Loader />}
      <Table sx={{ minWidth: 100 }} size="small"aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">S.no</StyledTableCell>
            {isWideScreen && (
              <StyledTableCell align="center">Name</StyledTableCell>
            )}
            <StyledTableCell align="center">Utr Id</StyledTableCell>
            <StyledTableCell align="center">Amount</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <StyledTableRow key={row.sno}>
              <StyledTableCell align="center" component="th" scope="row">
                {row.sno}
              </StyledTableCell>
              {isWideScreen && (
                <StyledTableCell align="center">{row.name}</StyledTableCell>
              )}
              <StyledTableCell align="center">{row.Utr}</StyledTableCell>
              <StyledTableCell align="center">{row.amount}</StyledTableCell>
              <StyledTableCell align="center">
                {row.status === "done" || row.status === "Done" ? (
                  <CheckCircleIcon color="success" />
                ) : row.status === "Failed" ? (
                  <CancelIcon sx={{ color: red[600] }} />
                ) : (
                  <PendingIcon color="primary" />
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px 0px",
        }}
      >
        <Pagination
          count={Math.ceil(totalCount / itemsPerPage)} // Assuming the API provides the total count
          page={currentPage}
          onChange={handleChangePage}
          shape="rounded"
          style={{ justifyContent: "flex-end" }}
        />
      </div>
    </TableContainer>
  );
}

export default TopHistory;
