import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import OrderComp from "./orderComp";
import Loader from "./loader/Loader";
import "./Order.css";
import leftbtn from "../assest/leftbtn.svg";
import rightbtn from "../assest/rightbtn.svg";
import Customsnackbar from "./snackbar/Customsnackbar";

function Order({ onbalance }) {
  const { id } = useParams();
  const [data, setData] = useState("null");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    color: "", // Default color
  });

  const handlebalance = (cost) => {
    onbalance(cost);
  };

  useEffect(() => {
    const fetchData = async () => {
      let tokenFromLocalStorage = localStorage.getItem("token");
      try {
        const response = await fetch(
          `https://otpseller.store/apitest/order.php?id=${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + tokenFromLocalStorage,
            },
          }
        );
        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData.msg);
          setLoading(false);
          const expirationTime = new Date(jsonData.msg.expires).getTime();
          const currentTime = new Date().getTime();
          if (jsonData.msg.status === "BANNED") {
            clearInterval(intervalId);
          } else if (currentTime > expirationTime) {
            clearInterval(intervalId);
            const newIntervalId = setInterval(() => {
              fetchData();
            }, 10000);
            intervalId = newIntervalId;
          }
        }
      } catch (error) {
        setSnackbarData({
          message: `Something is not wrong. Try again later.`,
          color: "error",
        });
        setOpen(true);
      }
    };
    let intervalId = setInterval(() => {
      fetchData();
    }, 1500);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [id]);

  const buyProduct = async (country, product, virtual, cost) => {
    let tokenFromLocalStorage = localStorage.getItem("token");
    setLoading(true);
    try {
      const response = await fetch(
        `https://otpseller.store/apitest/buyorder.php?buyid=${product}&services=${virtual}&cn=${country}&price=${cost}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenFromLocalStorage,
          },
        }
      );
      const data = await response.json();
      setLoading(false);
      if (data.status !== 400) {
        handlebalance(-cost);
        setSnackbarData({
          message: "New Order Created",
          color: "success",
        });
        setOpen(true);
        navigate(`/order/${data.msg}`);
      } else {
        setSnackbarData({
          message: data.msg,
          color: "error",
        });
        setOpen(true);
      }
    } catch (error) {
      setSnackbarData({
        message: `Something is not wrong. Try again later.`,
        color: "error",
      });
      setOpen(true);
    }
  };

  const banOrder = async (id, cost) => {
    setLoading(true);
    let tokenFromLocalStorage = localStorage.getItem("token");
    try {
      const response = await fetch(
        `https://otpseller.store/apitest/cancel.php?id=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenFromLocalStorage,
          },
        }
      );
      const data = await response.json();
      setLoading(false);
      if (data.status === 200) {
        handlebalance(cost);
        setSnackbarData({
          message: `Order Is Banned`,
          color: "success",
        });
        setOpen(true);
      } else {
        setSnackbarData({
          message: `Something is not wrong. Try again later.`,
          color: "error",
        });
        setOpen(true);
      }
    } catch (error) {
      setSnackbarData({
        message: `Something is not wrong with api. Try again later.`,
        color: "error",
      });
      setOpen(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const banOrderBuyOrder = async (id, country, product, virtual, cost) => {
    await banOrder(id, cost);
    buyProduct(country, product, virtual, cost);
  };
  return (
    <div className="divcenter">
      <Customsnackbar
        open={open}
        onClose={handleClose}
        message={snackbarData.message}
        color={snackbarData.color}
      />
      {loading && <Loader />}
      <p className="middle_id"> Order No. {id}</p>
      <OrderComp label="Product" value={data.product} />
      <OrderComp label="Country" value={data.country} />
      <OrderComp label="Price" value={data.price} />
      <OrderComp label="Operator" value={data.operator} />
      <OrderComp label="Created at" value={data.created_at} />
      <OrderComp label="Expires at" value={data.expires} />
      <OrderComp label="Status" value={data.status} />
      <div className="mn14xa">
        <p className="firstp">Number</p>
        <div className="f1lk5u">
          <button
            className="leftbtn btnn"
            type="button"
            title="Copy with country code"
            onClick={() => {
              navigator.clipboard.writeText(`${data.phone}`);
            }}
          >
            <img
              alt="Copy with country code"
              width="38"
              height="24"
              src={leftbtn}
              style={{ color: "transparent" }}
            />
            <span className="rightspan"></span>
          </button>
          <p className="hhi5q">
            <span id="text1" className="hvm">
              {data.phone}
            </span>
          </p>

          <button
            className="rightbtn btnn"
            type="button"
            title="Copy without country code"
            onClick={() => {
              let phoneNumberWithoutCountryCode;
              if (data.country === "india") {
                const countryCodeIndex = data.phone.indexOf("+");
                phoneNumberWithoutCountryCode =
                  countryCodeIndex !== -1
                    ? data.phone.substring(countryCodeIndex + 3)
                    : data.phone;
              } else {
                const countryCodeIndex = data.phone.indexOf("+");
                phoneNumberWithoutCountryCode =
                  countryCodeIndex !== -1
                    ? data.phone.substring(countryCodeIndex +   2)
                    : data.phone;
              }
              navigator.clipboard.writeText(phoneNumberWithoutCountryCode);
            }}
          >
            <img
              alt="Copy"
              width="24"
              height="24"
              src={rightbtn}
              style={{ color: "transparent" }}
            />
            <span className="right  span"></span>
          </button>
        </div>
      </div>
      <div className="mn14xa">
        <p className="firstp">Code from sms</p>
        {data && data.sms && data.sms.length > 0 ? (
          <>
            {data.sms.map((code, index) => (
              <div key={index} className="bblrdb mt-3">
                <p className="bgge2z">
                  <span className="l4hvm" id="code">
                    {code.code}
                  </span>
                </p>
                <button
                  className="rightbtn btnn"
                  type="button"
                  title="Copy code"
                  onClick={() => {
                    navigator.clipboard.writeText(code.code);
                  }}
                >
                  <img
                    alt="Copy"
                    width="24"
                    height="24"
                    src={rightbtn}
                    style={{ color: "transparent" }}
                  />
                  <span className="right span"></span>
                </button>
              </div>
            ))}
            {data.sms.map((text, index) => (
              <div key={index} className="o863q">
                <div className="q91mro">
                  <p className="ybxhr">{text.text}</p>
                </div>
              </div>
            ))}
            <Link to={`/${data.country}`}>
              <button className="button1">Complete Order</button>
            </Link>
            <button
              onClick={() =>
                buyProduct(
                  data.country,
                  data.product,
                  data.operator,
                  data.price
                )
              }
              className="button1"
            >
              Repeat Order
            </button>
          </>
        ) : (
          <>
            <div className="bblrdb">
              <p className="b5yj9y">
                <span className="l4hvm"></span>
              </p>
              <button className="f9kos" type="button">
                <img
                  alt="Copy"
                  width="24"
                  height="24"
                  src={rightbtn}
                  style={{ color: "transparent" }}
                />
                <span className="right  span"></span>
              </button>
            </div>
            {data.status === "BANNED" ? (
              <button
                onClick={() =>
                  buyProduct(
                    data.country,
                    data.product,
                    data.operator,
                    data.price
                  )
                }
                className="button1"
              >
                Repeat Order
              </button>
            ) : (
              <>
                <button
                  className="button1"
                  onClick={() => banOrder(id, data.price)}
                >
                  Ban order
                </button>
                <button
                  className="button1"
                  onClick={() =>
                    banOrderBuyOrder(
                      id,
                      data.country,
                      data.product,
                      data.operator,
                      data.price
                    )
                  }
                >
                  Ban order and Rebuy order
                </button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Order;
