import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import StepValue from "./stepValue/StepValue";
import { useParams } from "react-router-dom";
import Customsnackbar from "./snackbar/Customsnackbar";
import Cookies from "js-cookie";
import Loader from "./loader/Loader";

function Recharge() {
  const { amount } = useParams();
  const [name, setName] = useState("");
  const [utrId, setutrId] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    color: "",
  });
  function generateRandomString() {
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let randomString = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }
    return randomString;
  }
  let existingToken = Cookies.get("myRandomToken");
  if (!existingToken) {
    const randomToken = generateRandomString();
    Cookies.set("myRandomToken", randomToken, { expires: 3 });
  }
  async function formSubmit() {
    setLoading(true)
    if (name.length === 0 || utrId.length === 0) {
      setSnackbarData({
        message: "email and Utr cannot be null",
        color: "error",
      });
      setOpen(true);
      setLoading(false)
      return;
    }
    try {
      let existingToken = Cookies.get("myRandomToken");
      let tokenFromLocalStorage = localStorage.getItem("token");
      const response = await fetch(
        "https://otpseller.store/apitest/recharge.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenFromLocalStorage,
          },
          body: JSON.stringify({ name, utrId, amount, existingToken }),
        }
      );
      setLoading(false)
      const dataReceived = await response.json();
      if (dataReceived.status === 200) {
        setSnackbarData({
          message: dataReceived.msg,
          color: "success",
        });
        setOpen(true);
      } else {
        setSnackbarData({
          message: dataReceived.msg,
          color: "error",
        });
        setOpen(true);
      }
      setName('')
      setutrId('')
    } catch (error) {
      setSnackbarData({
        message: error,
        color: "error",
      });
      setOpen(true);
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <StepValue StepValues={1} />
      <Customsnackbar
        open={open}
        onClose={handleClose}
        message={snackbarData.message}
        color={snackbarData.color}
      />
      {loading && <Loader />}
      <h4 className="text-center pt-3">Pay the amount of {amount} Rs. </h4>
      <div className="row">
        <div className="col-12 col-md-6">
          <h5 className="ms-4 pt-4">
            Upi id : <b>ankit82772@jio</b>
          </h5>
          <img
            src={`https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=upi://pay?pa=ankit82772@jio%26pn=BharatPe%20Merchant%26cu=INR%26am=${amount}%26tn=${existingToken}%26tr=WHATSAPP_QR`}
            alt="QR Code"
          />
        </div>
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-12 col-md-12">
              <TextField
                helperText="Enter your name here..."
                id="demo-helper-text-aligned"
                label="Name"
                inputProps={{ maxLength: 15 }}
                name="PAYEE_NAME"
                onChange={(e) => setName(e.target.value)}
                margin="normal"
                value={name}
              />
              <TextField
                helperText="Enter your utr id here..."
                id="demo-helper-text-aligned"
                label="Utr Id"
                name="FIELD_1"
                maxLength="50"
                margin="normal"
                onChange={(e) => setutrId(e.target.value)}
                inputProps={{ maxLength: 20 }}
                value={utrId}
              />
              <h6 className="">After Pay Fill this details and Submit </h6>
            </div>
            <div className="ml-4 col-9 col-md-9 col-lg-9 pb-3">
              <button
                name="submit"
                className="btn btn-primary btn-block rounded-pill font1 "
                onClick={formSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Recharge;
